<template>
  <Header
    ref="transactionsHeaderRef"
    type="TRANSACTIONS"
    :title="t('menus.ledger')"
    updatedOn=""
    :showRegenerate="false"
    :showRegenerateButton="false"
  />

  <HeaderTabs
    :journalsTotal="formatNumber(entityStore?.entityDetail?.journalTotal)"
    :showIntegrations="true"
    :showJournalButton="true"
    @onAddJournal="onAddJournal"
    @onOpenIntegrationDialog="onOpenIntegrationDialog"
  />

  <JournalsScreening
    ref="journalsScreeningRef"
    :viewValue="viewValue"
    :listValue="listValue"
    :paramsData="paramsData"
    @onChangeView="onChangeView"
    @onChangeSort="onChangeSort"
    @onFilterData="onChangePage(1)"
    @onImportJournal="onImportJournal"
    @onCheckTableExpand="onCheckTableExpand"
    @onOpenBusinessEventDialog="onOpenBusinessEventDialog"
  />

  <ul
    v-if="entityStore.entityDetail?.progress?.externalJournalCsvUploadList?.length"
    class="elv-journal-upload-status-list"
  >
    <li
      v-for="(item, index) in entityStore.entityDetail?.progress?.externalJournalCsvUploadList"
      :key="index"
      class="elv-journal-upload-status-item"
    >
      <div class="elv-journal-upload-status-item__state">
        <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
          <template v-if="item.status === 'FINISHED'">
            <SvgIcon class="success" name="checkmark-filled" width="18" height="18" fill="#94C300" />
            {{ t('title.fileImportCompleted', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
          </template>
          <template v-else>
            <SvgIcon class="success" name="sources-sync-error" width="18" height="18" fill="#94C300" />
            {{ t('title.fileImportFailed', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
          </template>
        </template>
        <template v-else>
          <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
          {{ t('title.fileIsImporting', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
        </template>
      </div>
      <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
        <p class="elv-journal-upload-status-item__success">
          {{ t('common.successfully') }}: {{ formatNumber(item.successJournalEntryCount) }}
          {{ item.successJournalEntryCount > 1 ? t('common.entries') : t('common.entry') }},&nbsp;
          {{ formatNumber(item.successJournalCount) }}
          {{ item.successJournalCount > 1 ? t('report.journals') : t('report.Journal') }}
        </p>
        <div v-if="item.failCount" class="elv-journal-upload-status-item__failure">
          {{ t('common.failure') }}:&nbsp;
          <p>{{ formatNumber(item.failCount) }}</p>
          &nbsp;{{ item.failCount > 1 ? t('common.items') : t('common.item') }}
          <span v-if="item.failFileDownloadUrl" @click="onDownloadFailFile(item.failFileDownloadUrl)">{{
            t('button.clickDownloadAndReEdit')
          }}</span>
        </div>
      </template>

      <div
        v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)"
        v-loading="closeLoading && currentFileData.externalJournalCsvUploadId === item.externalJournalCsvUploadId"
        class="elv-journal-upload-status-item__close"
      >
        <SvgIcon name="common-close" width="18" height="18" fill="#838D95" @click="onCloseUploadStatus(item)" />
      </div>
    </li>
  </ul>

  <JournalsTable
    v-if="viewValue === 'LIST'"
    ref="journalListRef"
    :journalsParams="journalsParams"
    :tableHeight="tableHeight"
    :tableData="journalsData"
    :tableLoading="tableLoading"
    @onResetList="onChangePage(1)"
    @onChangePage="onChangePage"
    @openDetailOverlay="openDetailOverlay"
    @onChangePageSize="onChangePageSize"
  />

  <JournalGroupList
    v-else
    ref="journalGroupListRef"
    :listValue="listValue"
    :tableLoading="tableLoading"
    :journalGroupsParams="journalGroupsParams"
    @onResetList="onChangePage(1)"
    @onChangePage="onChangePage"
    @openDetailOverlay="openDetailOverlay"
    @onChangePageSize="onChangePageSize"
    @onRegenerateJournalGroup="onRegenerateJournalGroup"
    @onOpenBusinessEventDialog="onOpenBusinessEventDialog"
  />

  <AddJournalDialog
    ref="addJournalDialogRef"
    :model="journalDialogModel"
    tableType="journal"
    :auxiliaryAccountTypeId="auxiliaryAccountTypeId"
    :currentData="currentJournalData"
    :journalTypeList="journalTypeFilterList"
    @resetDetail="detailOverlayRef?.fetchJournalDetail()"
    @resetList="onChangePage(1)"
  />

  <IntegrationDialog ref="integrationDialogRef" @onResetList="onChangePage(1)" />

  <DetailOverlay
    ref="detailOverlayRef"
    :currentData="currentJournalData"
    @onEditJournal="onEditJournal"
    @onResetList="onChangePage(1)"
  />

  <ImportJournalDialog ref="importJournalDialogRef" />

  <BusinessEventDialog ref="businessEventDialogRef" @onResetList="getJournalGroupsTableData()" />
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { downloadFile } from '@/lib/download'
import Header from '../../components/Header.vue'
import TransactionsApi from '@/api/TransactionsApi'
import HeaderTabs from '../components/HeaderTabs.vue'
import { JournalListType } from '#/TransactionsTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import JournalsTable from './components/JournalsTable.vue'
import { find, sortBy, isEmpty, cloneDeep } from 'lodash-es'
import DetailOverlay from './components/DetailOverlay.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import JournalGroupList from './components/JournalGroupList.vue'
import JournalsScreening from './components/JournalsScreening.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import BusinessEventDialog from './components/BusinessEventDialog.vue'
import ImportJournalDialog from './components/ImportJournalDialog.vue'
import IntegrationDialog from './components/IntegrationDialog/Index.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'
import AddJournalDialog from '../../Transactions/components/AddJournalDialog.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const transactionStore = useTransactionStore()
const tableConfigStore = useReportsTableConfigStore()

const journalListRef = useTemplateRef('journalListRef')
const journalGroupListRef = useTemplateRef('journalGroupListRef')
const addJournalDialogRef = useTemplateRef('addJournalDialogRef')
const journalsScreeningRef = useTemplateRef('journalsScreeningRef')
const integrationDialogRef = useTemplateRef('integrationDialogRef')
const importJournalDialogRef = useTemplateRef('importJournalDialogRef')
const detailOverlayRef = useTemplateRef('detailOverlayRef')
const businessEventDialogRef = useTemplateRef('businessEventDialogRef')
const transactionsHeaderRef = useTemplateRef<InstanceType<typeof Header>>('transactionsHeaderRef')
const loading = ref(false)
const tableLoading = ref(true)
const closeLoading = ref(false)
const viewValue = ref('LIST')
const auxiliaryAccountTypeId = ref('')
const journalDialogModel = ref('add')
const currentJournalData: any = ref({})
// const viewValue = ref('GROUP')
const listValue = ref('EXPAND')
const postingStatus: any = ref('FINISHED')
const journalStatus: any = ref('FINISHED')
const currentFileData = ref<any>({})

const journalsParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const journalGroupsParams = ref({
  page: 1,
  limit: 20,
  sortByDateDirection: 'DESC'
})

const journalsData = ref<JournalListType>({
  total: 0,
  list: []
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const journalTypeFilterList = computed(() => {
  return transactionStore.journalTypeFilterList('journal')
})

const tableHeight = computed(() => {
  const updateProjectTipHeight = globalStore.freeProjectUpdateInfo(entityStore.entityDetail?.projectId) ? '28px' : '0px'
  if (globalStore.showUpGradeInfo) {
    return transactionsHeaderRef.value?.isShowRegeneratedInfo
      ? `calc(100vh - 273px - ${updateProjectTipHeight})`
      : `calc(100vh - 225px - ${updateProjectTipHeight} - ${
          entityStore.entityDetail?.progress?.externalJournalCsvUploadList?.length
            ? (entityStore.entityDetail?.progress?.externalJournalCsvUploadList?.length ?? 0) * 50 + 4
            : 0
        }px)`
  }
  return transactionsHeaderRef.value?.isShowRegeneratedInfo
    ? `calc(100vh - 271px - ${updateProjectTipHeight})`
    : `calc(100vh - 243px - ${updateProjectTipHeight} - ${
        entityStore.entityDetail?.progress?.externalJournalCsvUploadList?.length
          ? (entityStore.entityDetail?.progress?.externalJournalCsvUploadList?.length ?? 0) * 50 + 4
          : 0
      }px)`
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const paramsData = computed(() => {
  let params: Record<string, unknown> = {}
  switch (viewValue.value) {
    case 'LIST':
      params = {
        ...journalsParams.value
      }
      const entityJournalListFilter = transactionStore.entityJournalFilterList.find((item: any) => {
        return item.entityId === entityId.value
      })
      if (!isEmpty(entityJournalListFilter)) {
        params = {
          ...params,
          ...entityJournalListFilter.data
        }
        if (entityJournalListFilter.data?.source) {
          if (entityJournalListFilter.data?.source !== 'MANUAL') {
            const sourceData = accountStore.allSourceList.list.find((item: any) => {
              return (
                item?.sourceId === entityJournalListFilter.data?.source ||
                item?.externalJournalSourceId === entityJournalListFilter.data?.source ||
                item?.businessDataSourceId === entityJournalListFilter.data?.source
              )
            })
            if (isEmpty(sourceData)) {
              delete params.sourceId
              delete params.businessDataSourceId
              delete params.externalJournalSourceId
              const filterData = cloneDeep(entityJournalListFilter.data)
              delete filterData.source
              delete filterData.sourceId
              delete filterData.externalJournalSourceId
              delete filterData.businessDataSourceId
              transactionStore.editEntityJournalFilter(entityId.value, filterData)
            }
          }
          delete params.source
        }
        if (entityJournalListFilter.data?.currency?.length) {
          params.currency = entityJournalListFilter.data?.currency.map((item: string) => {
            return item.split('__')[1] ?? item
          })
        }
        if (entityJournalListFilter.data?.journalTypeIds?.length) {
          params.journalTypeIds = entityJournalListFilter.data?.journalTypeIds.filter((item: string) => {
            return transactionStore.journalTypeList.find((journalType: any) => {
              return journalType.journalTypeId === item
            })
          })
          const filterData: any = cloneDeep(entityJournalListFilter.data)
          filterData.journalTypeIds = params.journalTypeIds
          transactionStore.editEntityJournalFilter(entityId.value, filterData)
        }
      }
      break
    default:
      // Group
      params = {
        ...journalGroupsParams.value
      }
      const entityJournalGroupFilter = transactionStore.entityJournalGroupFilterList.find((item: any) => {
        return item.entityId === entityId.value
      })
      if (!isEmpty(entityJournalGroupFilter)) {
        params = {
          ...params,
          ...entityJournalGroupFilter.data
        }
        if (entityJournalGroupFilter.data?.currency?.length) {
          params.currency = entityJournalGroupFilter.data?.currency.map((item: string) => {
            return item.split('__')[1] ?? item
          })
        }
        if (entityJournalGroupFilter.data?.journalGroupConfigId) {
          if (
            isEmpty(
              find(transactionStore.businessEventList, {
                journalGroupConfigId: entityJournalGroupFilter.data?.journalGroupConfigId
              })
            )
          ) {
            delete params.journalGroupConfigId
            const filterData = cloneDeep(entityJournalGroupFilter.data)
            delete filterData.journalGroupConfigId
            transactionStore.editEntityJournalGroupFilter(entityId.value, filterData)
          }
        }
      }
      break
  }
  return params
})

const onImportJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.create) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  importJournalDialogRef.value?.onCheckImportJournalDialog()
}

const onOpenIntegrationDialog = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.businessData?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  integrationDialogRef.value?.onCheckJournalIntegrationDialog()
}

const onOpenBusinessEventDialog = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  businessEventDialogRef.value?.onCheckJournalBusinessEventDialog()
}

const onCloseUploadStatus = async (row: any) => {
  try {
    currentFileData.value = row
    closeLoading.value = true
    await LedgerApi.closeJournalCsvUploadStatus(entityId.value, row.externalJournalCsvUploadId)
    ElMessage.success(t('message.success'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    closeLoading.value = false
  }
}

const onDownloadFailFile = (failFileDownloadUrl: string) => {
  downloadFile(failFileDownloadUrl)
}

const getJournalsTableData = async () => {
  try {
    tableLoading.value = true
    const response = await transactionStore.fetchJournalList(entityId.value, paramsData.value)
    const list = response.list.map((item: any) => {
      return {
        ...item,
        entryList: item?.entryList?.length
          ? sortBy(item.entryList, (i: any) => {
              if (i.balanceType === 'Dr') {
                return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 1 : 0
              }
              if (i.balanceType === 'Cr') {
                return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 3 : 2
              }
              return 0
            })
          : []
      }
    })

    journalsData.value = {
      total: response.total,
      list
    }
    journalListRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onAddJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.create) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  journalDialogModel.value = 'add'
  currentJournalData.value = {}
  addJournalDialogRef.value?.onCheckAddJournalDialog()
}

const onEditJournal = (row: any) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  journalDialogModel.value = 'edit'
  currentJournalData.value = row
  addJournalDialogRef.value?.onCheckAddJournalDialog()
}

const openDetailOverlay = (data: any) => {
  currentJournalData.value = data
  detailOverlayRef.value?.onCheckDrawerStatus()
}

const getJournalGroupsTableData = async () => {
  try {
    tableLoading.value = true
    await transactionStore.fetchJournalGroupList(entityId.value, paramsData.value)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangeSort = (sort: string) => {
  if (viewValue.value === 'GROUP') {
    journalGroupsParams.value.page = 1
    journalGroupsParams.value.sortByDateDirection = sort
    getJournalGroupsTableData()
    transactionStore.fetchJournalGroupBusinessEventList(entityId.value)
  } else {
    journalsParams.value.sortDirection = sort
    journalsParams.value.page = 1
    getJournalsTableData()
  }
}

const onChangeView = (value: string) => {
  viewValue.value = value
  journalsParams.value.page = 1
  journalsParams.value.limit = 20
  journalsParams.value.sortDirection = 'DESC'
  journalGroupsParams.value.page = 1
  journalGroupsParams.value.limit = 20
  journalGroupsParams.value.sortByDateDirection = 'DESC'
  listValue.value = 'EXPAND'
  if (value === 'GROUP') {
    getJournalGroupsTableData()
    transactionStore.fetchJournalGroupBusinessEventList(entityId.value)
  } else {
    getJournalsTableData()
  }
}

const onRegenerateJournalGroup = async () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.journalStatus === 'JOURNAL_GROUP') return
  try {
    tableLoading.value = true
    await TransactionsApi.executeJournalGroup(entityId.value)
    ElMessage.warning(t('message.regeneratingBusinessEvents'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    ElMessage.error(error.message)
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onCheckTableExpand = (status: string) => {
  listValue.value = status
  // journalGroupListRef.value?.onCheckTableExpand(status)
}

const onChangePageSize = (limit: number) => {
  if (viewValue.value === 'GROUP') {
    journalGroupsParams.value.limit = limit
    journalGroupsParams.value.page = 1
    getJournalGroupsTableData()
  } else {
    journalsParams.value.limit = limit
    journalsParams.value.page = 1
    getJournalsTableData()
  }
}

const onChangePage = (page: number) => {
  if (viewValue.value === 'GROUP') {
    journalGroupsParams.value.page = page
    getJournalGroupsTableData()
  } else {
    journalsParams.value.page = page
    getJournalsTableData()
  }
}

const getAuxiliaryTypeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 1 })
    auxiliaryAccountTypeId.value = data.list?.[0]?.auxiliaryTypeId ?? ''
  } catch (error) {
    console.error(error)
  }
}

const getEntityDetailPostingStatus = async () => {
  if (
    postingStatus.value === 'DOING' &&
    entityStore.entityDetail?.progress.postingStatus === 'FINISHED' &&
    viewValue.value === 'LIST'
  ) {
    // ElMessage.success(t('message.recalculateCostBasisSuccess'))
    getJournalsTableData()
  }
  if (
    journalStatus.value === 'JOURNAL_GROUP' &&
    entityStore.entityDetail?.progress.journalStatus === 'FINISHED' &&
    viewValue.value === 'GROUP'
  ) {
    getJournalGroupsTableData()
  }
  journalStatus.value = entityStore.entityDetail?.progress.journalStatus
  postingStatus.value = entityStore.entityDetail?.progress.postingStatus
}

watch(
  () => entityStore.entityDetail,
  async (newValue, oldValue) => {
    if (newValue.entityId !== oldValue?.entityId) {
      journalStatus.value = entityStore.entityDetail?.progress.journalStatus
      postingStatus.value = entityStore.entityDetail?.progress.postingStatus
    }
    getEntityDetailPostingStatus()
  },
  { immediate: true, deep: true }
)

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-ledger-journals') {
        loading.value = true
        tableConfigStore.processTableConfig = []
        await getJournalsTableData()
        entityStore.fetchCurrencyList(entityId.value)
        accountStore.fetchEntityAccountList(entityId.value, { withDeleted: true })
        postingStatus.value = entityStore.entityDetail?.progress.postingStatus
        getAuxiliaryTypeList()
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss">
.elv-journal-upload-status-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;

  .elv-journal-upload-status-item {
    width: 100%;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #abc0f5;
    background: rgba(229, 237, 255, 0.2);
    gap: 32px;
    position: relative;

    .elv-journal-upload-status-item__state {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        width: 18px;
        height: 18px;
        display: block;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-journal-upload-status-item__success {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elv-journal-upload-status-item__failure {
      display: flex;
      align-items: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      p {
        color: #df2200;
      }

      span {
        color: #1753eb;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: lowercase;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .elv-journal-upload-status-item__close {
      position: absolute;
      right: 8px;
      cursor: pointer;

      &:hover svg {
        fill: #1343bf;
      }

      .el-loading-mask {
        .circular {
          width: 28px;
          height: 28px;
          position: relative;
          top: 6px;
          left: -6px;
        }
      }
    }
  }
}
</style>
