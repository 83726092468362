<template>
  <div>
    <Screening>
      <template #screening>
        <div class="elv-journals-screening-item">
          <span class="elv-journals-screening-item__label">{{ t('common.view') }}:</span>
          <el-select
            :model-value="props.viewValue"
            placeholder=" "
            popper-class="elv-journals-screening-popper"
            class="elv-journals-screening-view"
            @change="onChangeView"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div
          v-if="props.viewValue === 'GROUP' && transactionStore.journalGroupList.total > 0"
          class="elv-journals-screening-item"
        >
          <span class="elv-journals-screening-item__label">{{ t('common.list') }}:</span>
          <el-select
            :model-value="listValue"
            placeholder=" "
            popper-class="elv-journals-screening-popper"
            class="elv-journals-screening-list"
            @change="onCheckTableExpand"
          >
            <el-option
              v-for="item in listOptions"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="elv-journals-screening-item">
          <span class="elv-journals-screening-item__label">{{ t('common.sort') }}:</span>
          <el-select
            v-model="sortValue"
            placeholder=" "
            popper-class="elv-journals-screening-popper"
            class="elv-journals-screening-sort"
            @change="onChangeSort"
          >
            <el-option
              v-for="item in sortOptions"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div
          v-if="
            (props.viewValue === 'GROUP' && (transactionStore.journalGroupList.total > 0 || filterTotal)) ||
            props.viewValue === 'LIST'
          "
          class="elv-journals-screening-item"
        >
          <div @click="onOpenFilterOverlay">
            <div v-if="!filterTotal" class="elv-journals-screening-item-filter-add">
              <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
            </div>
            <div v-else class="elv-journals-screening-item-filter-count">
              {{ t('common.filter') }}: {{ filterTotal }}
            </div>
          </div>
        </div>
      </template>
      <template #operating>
        <Operating
          :viewType="props.viewValue"
          :paramsData="props.paramsData"
          @onImportJournal="emit('onImportJournal')"
          @onOpenBusinessEventDialog="emit('onOpenBusinessEventDialog')"
        />
      </template>
    </Screening>

    <FilterOverlay ref="filterOverlayRef" @onFilterData="emit('onFilterData')" />

    <GroupFilterOverlay ref="groupFilterOverlayRef" @onFilterData="emit('onFilterData')" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Operating from './Operating.vue'
import FilterOverlay from './FilterOverlay.vue'
import { $t, transformI18n } from '@/i18n/index'
import GroupFilterOverlay from './GroupFilterOverlay.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import Screening from '@/pages/Financials/Project/components/Screening.vue'

const props = defineProps({
  viewValue: {
    type: String,
    default: 'LIST'
  },
  listValue: {
    type: String,
    default: 'COLLAPSE'
  },
  paramsData: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const transactionStore = useTransactionStore()

const filterOverlayRef = useTemplateRef('filterOverlayRef')
const groupFilterOverlayRef = useTemplateRef('groupFilterOverlayRef')
const sortValue = ref('DESC')
const typeOptions = reactive([
  {
    value: 'LIST',
    label: $t('report.JournalList')
  },
  {
    value: 'GROUP',
    label: $t('common.businessEvent')
  }
])

const sortOptions = reactive([
  {
    value: 'DESC',
    label: $t('common.newestToOldest')
  },
  {
    value: 'ASC',
    label: $t('common.oldestToNewest')
  }
])

const listOptions = reactive([
  {
    value: 'COLLAPSE',
    label: $t('common.collapseAll')
  },
  {
    value: 'EXPAND',
    label: $t('common.expandAll')
  }
])

const emit = defineEmits([
  'onChangeSort',
  'onChangeView',
  'onFilterData',
  'onImportJournal',
  'onCheckTableExpand',
  'onOpenBusinessEventDialog'
])

const entityId = computed(() => {
  return String(route?.params?.entityId)
})

const filterTotal = computed(() => {
  const filterList =
    props.viewValue === 'GROUP'
      ? transactionStore.entityJournalGroupFilterList
      : transactionStore.entityJournalFilterList
  return (
    filterList.find((item: any) => {
      return item.entityId === entityId.value
    })?.total ?? 0
  )
})

const onOpenFilterOverlay = () => {
  if (props.viewValue === 'GROUP') {
    groupFilterOverlayRef.value?.onCheckJournalGroupDrawerStatus()
  } else {
    filterOverlayRef.value?.onCheckDrawerStatus()
  }
}

const onChangeSort = () => {
  emit('onChangeSort', sortValue.value)
}

const onChangeView = (value: string) => {
  emit('onChangeView', value)
}

const onCheckTableExpand = (value: string) => {
  emit('onCheckTableExpand', value)
}
</script>

<style lang="scss">
.elv-journals-screening-item {
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #dde1e6;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  .elv-journals-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .elv-journals-screening-item-filter-add {
    display: flex;
    align-items: center;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    svg {
      fill: #939ba2;
      margin-right: 8px;
    }
  }

  .elv-journals-screening-item-filter-count {
    display: flex;
    padding: 0px 11px;
    height: 19px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    background: #5e85eb;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.elv-journals-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
