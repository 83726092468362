<template>
  <div class="elv-accounts-header-main-right-content">
    <template v-if="props.viewType === 'LIST'">
      <el-button class="elv-accounts-header-main-right__import" link @click="emit('onImportJournal')">
        <SvgIcon name="Import-document" width="16" height="16" />
        <span class="elv-accounts-header-main-right__import-text">{{ t('button.importCounterparty') }}</span>
      </el-button>

      <elv-export-button
        :params="exportParams"
        :no-permission="
          ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.journal?.viewList
        "
      />
    </template>

    <template v-if="props.viewType === 'GROUP'">
      <el-button link class="elv-financials-header-main-right__operation" @click="emit('onOpenBusinessEventDialog')">
        <SvgIcon name="project-setting" width="14" height="14" />
        {{ t('button.configuration') }}
      </el-button>

      <el-button
        v-if="transactionStore.businessEventList.length || transactionStore.journalGroupList.total > 0"
        link
        :loading="regenerateGroupLoading"
        :disabled="entityStore.entityDetail?.progress.journalStatus === 'JOURNAL_GROUP'"
        class="elv-financials-header-main-right__operation"
        @click="onRegenerateJournalGroup"
      >
        <template v-if="entityStore.entityDetail?.progress.journalStatus !== 'JOURNAL_GROUP'">
          <SvgIcon name="renew" width="14" height="14" />
          {{ t('button.regenerate') }}
        </template>
        <template v-else>
          <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" class="elv-syncing" />
          {{ t('message.regeneratingBusinessEvents') }}
        </template>
      </el-button>
    </template>
  </div>

  <ElvMessageBox
    v-if="false"
    ref="syncMessageBoxRef"
    :showCancelButton="false"
    :loading="syncLoading"
    :confirmButtonText="t('button.iKnow')"
    :title="t('button.syncIntegration', { platform: 'Xero' })"
    @onConfirmEvent="onConfirmSync"
  >
    <template #content>
      <span class="elv-confirm-content-info__title">{{ t('message.syncIntegrationInfo') }}</span></template
    >
  </ElvMessageBox>

  <AccountMappingsDialog ref="accountMappingsRef" mode="create" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash-es'
import { ElMessage } from 'element-plus'
import TransactionsApi from '@/api/TransactionsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useTransactionStore } from '@/stores/modules/transactions'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import AccountMappingsDialog from '@/components/Reports/AccountMappingsDialog.vue'

const props = defineProps({
  viewType: {
    type: String,
    default: 'LIST'
  },
  paramsData: {
    type: Object,
    required: true
  },
  journalGroupTotal: {
    type: Number,
    default: 0
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const emit = defineEmits(['onCheckBatchTransactions', 'onOpenBusinessEventDialog', 'onImportJournal'])

const syncLoading = ref(false)
const regenerateGroupLoading = ref(false)
const syncMessageBoxRef = useTemplateRef('syncMessageBoxRef')
const accountMappingsRef = useTemplateRef('accountMappingsRef')

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const exportParams: any = computed(() => {
  const params = { ...cloneDeep(props.paramsData) }
  delete params?.page
  delete params?.limit
  return {
    type: 'JOURNAL_ENTRY',
    extra: { ...params }
  }
})

const onConfirmSync = async () => {
  syncMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onRegenerateJournalGroup = async () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  try {
    regenerateGroupLoading.value = true
    await TransactionsApi.executeJournalGroup(entityId.value)
    ElMessage.warning(t('message.regeneratingBusinessEvents'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    ElMessage.error(error.message)
    console.log(error)
  } finally {
    regenerateGroupLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.elv-financials-header-main-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;

  .elv-accounts-header-main-right-content {
    display: flex;
    align-items: center;
  }
}

.elv-financials-header-main-right__edit,
.elv-financials-header-main-right__sync {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;

  svg {
    fill: #1e2024;
    margin-right: 8px;
  }

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):active {
    background-color: #fff;
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-accounts-header-main-right__import {
  position: relative;
  margin-right: 8px;
  color: #1e2024;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    fill: #838d95;
  }

  span {
    margin-left: 6px;
  }

  &:hover {
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.elv-financials-header-main-right__operation {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;
  margin-left: 16px;
  padding: 0px;
  border: 0px;

  svg {
    fill: #838d95;
    margin-right: 6px;
  }

  .elv-syncing {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 8px;
    animation: loading-rotate 2s linear infinite;
  }

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):active {
    background-color: #fff;
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-financials-header-main-right__sync {
  img {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 8px;
  }

  .elv-syncing {
    animation: loading-rotate 2s linear infinite;
  }
}
</style>
